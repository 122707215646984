<template>
    <footer class="footer">
        <div class="footer-content">
            <!-- 關於我們區塊 -->
            <div class="footer-section">
                <h2>About Us</h2>
                <div class="green-line"></div>
                <p class="about-text">
                    臺中市體育總會足球委員會致力於推廣足球運動，
                    提供優質的訓練環境與比賽機會，促進臺中市足球運動發展。
                </p>
                <div class="social-links">


                    <a href="https://www.facebook.com/groups/576604161850781" target="_blank" class="social-icon">
                        <i class="fab fa-facebook-f" aria-label="Facebook"></i>
                    </a>
                    <a href="https://line.me/ti/p/@677ciirx" target="_blank" class="social-icon">
                        <i class="fab fa-line" aria-label="Line"></i>
                    </a>
                    <a href="mailto:service@tcfc.org.tw" class="social-icon">
                        <i class="fas fa-envelope" aria-label="Email"></i>
                    </a>

                </div>
            </div>

            <!-- 快速連結區塊 -->
            <div class="footer-section">
                <h2>Quick Links</h2>
                <div class="green-line"></div>
                <ul class="footer-links">
                    <li><a href="javascript:void(0)">組織簡介</a></li>
                    <li><a href="javascript:void(0)">願景與使命</a></li>
                    <li><a href="javascript:void(0)">組織架構</a></li>
                    <li><a href="javascript:void(0)">主席簡介</a></li>
                    <li><a href="javascript:void(0)">地方聯絡窗口</a></li>
                </ul>
            </div>

            <!-- 服務項目區塊 -->
            <div class="footer-section">
                <h2>Services</h2>
                <div class="green-line"></div>
                <ul class="footer-links">
                    <li><a href="javascript:void(0)">規章制度</a></li>
                    <li><a href="javascript:void(0)">財務報告</a></li>
                    <li><a href="javascript:void(0)">會議紀錄</a></li>
                    <li><a href="javascript:void(0)">活動資訊</a></li>
                    <li><a href="javascript:void(0)">比賽公告</a></li>
                </ul>
            </div>

            <!-- 聯絡資訊區塊 -->
            <div class="footer-section">
                <h2>Contact Information</h2>
                <div class="green-line"></div>
                <div class="contact-info">
                    <p><i class="fas fa-phone gold-icon"></i><a href="tel:+88422588185"> 2258 8185</a></p>
                    <p><i class="fas fa-envelope gold-icon"></i><a
                            href="mailto:service@tcfc.org.tw">service@tcfc.org.tw</a></p>
                    <p><i class="fas fa-map-marker-alt gold-icon"></i>407027台中市西屯區朝馬路108號</p>
                </div>

            </div>
        </div>

        <div class="footer-bottom">
            <p>&copy; 2025© TCFC 臺中市體育總會足球委員會</p>
        </div>
    </footer>
</template>
